import { Row, Col } from "antd";
import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { bounce } from "react-animations";
import { fadeInRight } from "react-animations";
import Radium, { StyleRoot } from "radium";

const styles = {
  bounce: {
    animation: "x 1s",
    animationName: Radium.keyframes(bounce, "bounce"),
  },
  fadeInRight: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
};

const settings = {
  dots: true,
  autoplay: true,
  autoplaySpeed: 1500,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const About = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <StyleRoot>
      <div style={styles.fadeInRight}>
        <Row style={{ backgroundColor: "#00738e", paddingBottom: "4vh" }}>
          <Col xl={2} md={2} xs={1} />
          <Col xl={10} md={22} xs={23}>
            <img src="/images/1.png" className="bl--img-page" />
          </Col>
          <Col
            xl={10}
            md={22}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh",
            }}
          >
            <p className="bl--description-vert" style={{ marginTop: "8vh" }}>
              Pourquoi nous sommes les meilleurs
            </p>
            <p className="bl--description" style={{ marginTop: "4vh" }}>
              BEST LAB vous propose un savoir, un savoir-faire et une expérience
              de plusieurs années aux services de nos clients dans les secteurs
              suivants :
            </p>
            <br /> <br />
            <ul className="bl--ul">
              <li>La recherche et enseignement supérieur</li>
              <li>L’environnement</li>
              <li>L’industrie ( pharmaceutiques , agroalimentaires …)</li>
              <li>Santé ( Hôpitaux , Laboratoires d’analyses…)</li>
            </ul>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>
      <Row style={{ padding: "4vh" }}>
        <Col span={24}>
          <div>
            <Slider {...settings}>
              <div>
                <img className="bl--slider-img" src="/images/1.png" />
              </div>
              <div>
                <img className="bl--slider-img" src="/images/2.png" />
              </div>
              <div>
                <img className="bl--slider-img" src="/images/3.png" />
              </div>
              <div>
                <img className="bl--slider-img" src="/images/4.png" />
              </div>
              <div>
                <img className="bl--slider-img" src="/images/5.png" />
              </div>
              <div>
                <img className="bl--slider-img" src="/images/6.png" />
              </div>
            </Slider>
          </div>
        </Col>
      </Row>
      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center",
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default About;
