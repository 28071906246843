import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Menu, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { fadeOut } from "react-animations";
import Radium, { StyleRoot } from "radium";
const { Header, Content, Footer } = Layout;

const styles = {
  fadeOut: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeOut, "fadeOut"),
  },
};
const TopNavLayout = ({ children, AppStore, props }) => {
  const [state, setState] = useState({ current: "mail" });
  useEffect(() => {}, []);

  const handleClick = (e) => {
    setState({ current: e.key });
  };

  const customFooter = (
    <>
      <Row
        style={{
          backgroundColor: "#444644",
          minHeight: "30vh",
          paddingTop: "1vh",
        }}
      >
        <Col xl={2} md={0} xs={0}></Col>
        <Col xl={7} md={12} xs={24}>
          <div>
            <span className="bl--footer-title">
              <span>About us</span>
            </span>
          </div>
          <img className="bl--img-footer" src="/images/best_lab_gris.png" />
          <div style={{ marginTop: "4vh" }}>
            <p className="bl--paragraph-footer">Bio-Equipements Sciences</p>
            <p className="bl--paragraph-footer">Technologie de Laboratoire</p>
          </div>
        </Col>
        <Col xl={7} md={12} xs={24}>
          <div>
            <span className="bl--footer-title">
              <span>Entrer en contact</span>
            </span>
          </div>
          <div style={{ marginTop: "4vh" }}>
            <p className="bl--paragraph-footer">
              <b>Addresse:</b> Av. Fethi Zouhir, Rés. L’Oasis, 2émé etage,{" "}
              <br />
              App. N° 4 cité la Ghazelle, 2083-Ariana.
            </p>
            <p className="bl--paragraph-footer">
              <b>Téléphone:</b> + 216 71 877 371 <br />
              <b>Mobile: </b> + 216 71 877 154 <br />
            </p>
            <p className="bl--paragraph-footer">
              <b>Email:</b> best.lab@topnet.tn
            </p>
          </div>
        </Col>
        <Col xl={7} md={12} xs={24}>
          <div>
            <span className="bl--footer-title">
              <span>Social Media</span>
            </span>
            <p>
              <a
                target="_blank"
                href={"https://www.facebook.com/BEST-LAB-107230977871645"}
              >
                <img style={{ cursor: "pointer" }} src="/images/fb.png"></img>
              </a>
              <img
                style={{ cursor: "pointer", marginLeft: "10px" }}
                src="/images/linkedin.png"
              ></img>
            </p>

            <div style={{ marginTop: "8vh" }}>
              <span className="bl--subtitle-home" style={{ color: "white" }}>
                The <span className="bl--subtitle-home-big">Best</span> for a
                Reason...
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div
        style={{
          minHeight: "4vh",
          backgroundColor: "#5fb3c3",
          textAlign: "center",
        }}
      >
        <p className="bl--description">2022 © Tous les droits sont réservés</p>
      </div>
    </>
  );

  return (
    <Layout style={{ height: "100vh" }}>
      <Header style={{ padding: 0 }}>
        <Row>
          <Col span="14" style={{ textAlign: "center" }}>
            <Link to="/">
              <img src="/images/best_lab_bleu.png" class="bl--logo" />
            </Link>
          </Col>
          <Col span="10">
            <Menu
              onClick={handleClick}
              selectedKeys={[state.current]}
              mode="horizontal"
              theme="light"
              float="right"
            >
              <Link to={"/"}>
                <Menu.Item key="1">Home</Menu.Item>
              </Link>
              <Link to={"/about"}>
                <Menu.Item key="2">Qui somme nous</Menu.Item>
              </Link>
              <Link to={"/services"}>
                <Menu.Item key="3">Nos Services</Menu.Item>
              </Link>
              <Link to={"/contact"}>
                <Menu.Item key="4">Contact</Menu.Item>
              </Link>
            </Menu>
          </Col>
        </Row>
      </Header>
      <Layout style={{ flex: 1 }}>
        <Content
          style={{
            padding: 24,
            overflowY: "auto",
          }}
        >
          {children}
          <div>{customFooter}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default TopNavLayout;
